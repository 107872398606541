@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }

  body {
    overflow-x: hidden;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

button.disabled {
  @apply cursor-not-allowed bg-gray-300 text-gray-500 !important;
}

/* Leaflet */

.leaflet-pane,
.leaflet-map-pane {
  @apply w-full !important;
}

.leaflet-container {
  z-index: 1 !important;
}

/* React PDF */

.react-pdf__Page,
.react-pdf-wrapper canvas {
  @apply !mt-3 !h-full !min-h-0 !w-full !min-w-0;
}

/* React Datalist */

.react-datalist-input__textbox {
  @apply flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white;
}

.react-datalist-input__listbox {
  @apply w-full rounded-xl border border-gray-200 bg-white/0 dark:!border-white/10;
}

.react-datalist-input__listbox-option {
  @apply flex h-12 w-full cursor-pointer items-center justify-between px-3 text-sm hover:bg-gray-100 dark:hover:bg-white/10;
}
